import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { ShowHide } from "./concerns/showHide"
import { VariousHelpers } from "./concerns/various_helpers"
import { HasIntervalOutlets } from "./concerns/playlist/has_interval_outlets"
import NoSleep from "@scottjgilroy/no-sleep" // es un fork, de un fork de nosleep.js con fix para PWA en iOS
import { Howl } from "howler"

export default class extends Controller {

  static outlets = [
    "navbar",
    // los distintos tipos de inteval tienen distintos identifiers
    "basic-interval",
    "loop-interval",
    "metronome",
  ]

  static targets = [
    "timerName", "intervalName",
    "toggleButton", "restartButton",
    "finishEarlyButton",
    "editor",
    "durationMessage", "totalElapsedTimeMessage", "elapsedTimeMessage", "clickMessage",
    "actionMessage", "nextActionMessage",
    "soundHelp",
    "volumeSelect",
    "clickAudio", "phaseChangeAudio", "metronomeChangeAudio", "endAudio", "startAudio"
  ]

  static values = {
    state: String,
    name: String,
    strLoading: String,
    strStart: String,
    strStop: String,
    strContinue: String,
    strFinished: String,
    strNext: String,
    volume: Number
  }

  setVolume(){
    if (this.hasVolumeSelectTarget) {
      this.volumeValue = this.volumeSelectTarget.value/ 100
      this.preloadAudios() // audios are loaded with the volume value
      this.playAudio("click")
    }
  }

  initialize() {
    Loggable(this, {debug: false})
    ShowHide(this, {hiddenClassName: "d-none"})
    VariousHelpers(this)
    HasIntervalOutlets(this)
  }

  connect() {
    this.setState("loading")

    this.setupBrowser()

    this.highlightClassName = "list-group-item-secondary"

    if (this.hasVolumeSelectTarget) {
      this.volumeValue = this.volumeSelectTarget.value/ 100
    }

    this.noSleep = new NoSleep()
    this.worker = new Worker("/metronome-worker.js")

    this.intervalIndex = 0
    this.currentClick = 0
    this.listenOutlets()

    this.setState("stopped")

    // no muestro el botón de play si no hay intervals.
    if (this.loadIntervals().length === 0) {
      this.hide(this.toggleButtonTarget)
    }
  }

  disconnect() {
    if (this.worker) {
      this.worker.terminate()
    }
    this.unloadAudios()
    this.deSetupBrowser()
  }

  togglePlay() {
    if (this.stateValue === "stopped" || this.stateValue === "finished") {
      this.preloadAudios()
      // es importante que este directo acá en la función y no dentro de otra función, para que browser vea que es user-triggerd y habilite sonido.
      let soundEnabler = new Howl({src: [this.clickAudioTarget.src]})
      soundEnabler.play()
      this.preStart()
    } else if (this.stateValue === "playing") {
      this.stop()
    }
  }

  preStart() {
    this.setState("pre-playing")

    this.clearHighlighCurrentInterval()
    this.clearPlayerMessages()
    this.clickMessageTarget.innerText = `...`
    // this.playAudio("click") - acaba de sonar el del togglePlay()
    setTimeout(() => {
      this.clickMessageTarget.innerText = `..`
      this.playAudio("click")
      setTimeout(() => {
        this.clickMessageTarget.innerText = `.`
        this.playAudio("click")
        this.startOrContinue()
      }, 800)
    }, 800)
  }

  startOrContinue(){
    this.log("startOrContinue")
    if (this.currentClick > 0) {
      this.continue()
    } else {
      this.start()
    }
  }

  start() {
    this.loadIntervals()
    this.setState("playing")

    // must be in user-triggered function
    this.noSleep.enable()

    this.startTickWorker()

    this.currentInterval().start()
  }

  continue(){
    this.setState("playing")

    // must be in user-triggered function
    this.noSleep.enable()

    this.startTickWorker()

    this.currentInterval().continue()
  }

  startTickWorker(){
    this.worker.postMessage('start');
    this.worker.onmessage = (e) => {
      if (e.data === 'tick') {
        this.click()
      }
    };
  }

  stop() {
    this.setState("stopped")

    this.noSleep.disable()
    this.worker.postMessage('stop');
    if (this.currentInterval()) {
      this.currentInterval().stop()
    }
  }

  restart(){
    this.stop()
    this.intervalIndex = 0
    this.currentClick = 0
    this.currentInterval().initClicks()
    this.preStart()
  }

  finish(){
    this.stop()
    this.playAudio("end")
    this.setState("finished")
    this.clearHighlighCurrentInterval()
  }

  click() {
    this.currentInterval().click()
    if (this.stateValue !== "finished") {
      this.render()
      this.currentClick++
    }
    document.dispatchEvent(new CustomEvent("timer:click:end"))
  }

  render(){
    this.intervalNameTarget.innerText = this.currentInterval().nameValue
    this.highlightCurrentInterval()
    this.renderElapsedTime()
  }

  clearHighlighCurrentInterval(){
    this.element.querySelectorAll(".playlist--interval-container").forEach((e) => {
      e.classList.remove(this.highlightClassName)
    })
  }
  highlightCurrentInterval(){
    let currentContainer = this.currentInterval().element.closest(".playlist--interval-container")
    this.element.querySelectorAll(".playlist--interval-container").forEach((e) => {
      if (e === currentContainer) {
        e.classList.add("list-group-item-secondary")
      } else {
        e.classList.remove(this.highlightClassName)
      }
    })
  }

  renderElapsedTime(){
    let humanClick = this.currentClick + 1
    let message = `${Math.floor(humanClick / 60)}:${(humanClick % 60).toString().padStart(2, '0')}`
    if (this._totalDuration) {
      message += ` / ${Math.floor(this._totalDuration / 60)}:${(this._totalDuration % 60).toString().padStart(2, '0')}`
    }
    this.totalElapsedTimeMessageTarget.innerText = message
  }

  hasAudio(name) {
    return this[`has${this.capitalize(name)}AudioTarget`]
  }

  preloadAudios(){
    let names = ["click", "phaseChange", "metronomeChange", "end", "start"]
    this._preloadedAudios = []
    names.forEach((name) => {
      if (this.hasAudio(name)) {
        this._preloadedAudios[name] = this.howlForAudioTarget(this[`${name}AudioTarget`])
      }
    })
  }

  unloadAudios(){
    if (this._preloadedAudios) {
      this._preloadedAudios.forEach((audio) => {
        audio.unload()
      })
      this._preloadedAudios = []
    }
  }

  howlForAudioTarget(audioTarget){
    let sources
    if (audioTarget.src === "") {
      sources = [...audioTarget.querySelectorAll("source")].map((s) => { return s.src })
    } else {
      sources = [audioTarget.src]
    }
    let sound = new Howl({
      src: sources,
      volume: this.volumeValue,
      autosuspend: false,
      onload: () => {
        this.log("howler loaded")
      },
      onloaderror: (id, error) => {
        this.error("howler loaderror", error)
      },
      onplayerror: (id, error) => {
        this.error("howler playerror", error)
      }
    })
    return sound
  }

  playAudio(audioName){
      if (Howler.ctx.state === 'suspended' || Howler.ctx.state === 'interrupted') {
        // en teoría Howler lo hace solo pero hay edge cases en iOS.
        Howler.ctx.resume()
      }
      if (this.hasAudio(audioName)) {
        // Los sonidos fueron pre-cargados en preloadAudios()
        this._preloadedAudios[audioName].play()
    }
  }

  startNextMetronome() {
    this.clearPlayerMessages()
    if (this.onLastInterval()) {
      this.finish()
    } else {
      this.playAudio("metronomeChange")
      this.intervalIndex++
      this.currentInterval().start()
      this.currentClick-- // para compensar el click adicional
      this.click() // para que no quede 1s en el medio entre metronomos.
    }
  }

  // el elemento cuyo envento dispare este método debe tener un data-interval-index
  skipToInterval(event) {
    if (this.stateValue === "playing") {
      let index = parseInt(event.currentTarget.dataset.intervalIndex)
      this.log("skipToInterval", index)
      this.startMetronome(index)
    } else {
      this.log("skipToInterval: not playing")
    }
  }

  startMetronome(index) {
    this.clearPlayerMessages()
    this.currentInterval().stop()

    this.intervalIndex = index
    this.currentInterval().start()
    this.currentClick-- // para compensar el click adicional
    this.click() // para que no quede 1s en el medio entre metronomos.
  }

  setState(newValue) {
    this.stateValue = newValue
    this.showAll(this.editorTargets)
    this.toggleNavigation() // after setting stateValue
    switch (this.stateValue) {
      case "loading":
      break
      case "stopped":
        this.show(this.toggleButtonTarget)
        if (this.currentClick > 0) {
          this.toggleButtonTarget.innerText = this.strContinueValue
          this.showAll([this.restartButtonTarget,this.finishEarlyButtonTarget])
        } else {
          this.toggleButtonTarget.innerText = this.strStartValue
          this.hideAll([this.restartButtonTarget,this.finishEarlyButtonTarget])
        }
        this.toggleButtonTarget.disabled = false
        break
      case "finished":
        this.clearPlayerMessages()
        this.actionMessageTarget.innerText = this.strFinishedValue
        this.toggleButtonTarget.innerText = this.strStartValue
        this.hideAll([this.toggleButtonTarget,this.finishEarlyButtonTarget])
        this.show(this.restartButtonTarget)
        this.toggleButtonTarget.disabled = false
        document.dispatchEvent(new CustomEvent("timer:finished", {detail: {elapsedSeconds: this.currentClick}}))
        break
      case "pre-playing":
        this.hideAll(this.editorTargets)
        this.hide(this.toggleButtonTarget)
        this.hideAll([this.restartButtonTarget,this.finishEarlyButtonTarget])
        this.toggleButtonTarget.innerText = this.strStopValue
        this.toggleButtonTarget.disabled = false
        break
      case "playing":
        this.hideAll(this.editorTargets)
        this.show(this.toggleButtonTarget)
        this.toggleButtonTarget.innerText = this.strStopValue
        this.toggleButtonTarget.disabled = false
        break
      default:
        this.hideAll([this.restartButtonTarget,this.finishEarlyButtonTarget])
        this.log(`setState: unexpected state ${this.stateValue}`)
        this.toggleButtonTarget.innerText = "error"
        this.toggleButtonTarget.disabled = true
    }
  }

  listenOutlets() {
    document.addEventListener("interval:durationChanged", (event) => {
      this.renderDuration()
    })
    document.addEventListener("interval:stateChanged", (event) => {
      if (event.detail.state === "finished") {
        this.startNextMetronome()
      }
    })
  }

  calculateTotalDuration() {
    this._totalDuration = 0
    this.loadIntervals().forEach((interval) => {
      this._totalDuration += interval._totalDuration
    })
  }

  renderDuration() {
    this.calculateTotalDuration()
    this.durationMessageTarget.innerText = `${Math.floor(this._totalDuration / 60)}:${(this._totalDuration % 60).toString().padStart(2, '0')}`
  }

  renderNextIntervalPreview() {
    if (this.onLastInterval()){
      this.nextActionMessageTarget.innerText = ""
    } else {
      this.nextInterval().renderFirstActionPreview()
    }
  }

  clearPlayerMessages() {
    this.clickMessageTarget.innerText = ""
    this.totalElapsedTimeMessageTarget.innerText = ""
    this.elapsedTimeMessageTarget.innerText = ""
    this.actionMessageTarget.innerText = ""
    if (this.hasNextActionMessageTarget) {
      this.nextActionMessageTarget.innerText = ""
    }
  }

  setupBrowser(){
    this.setAudioSession("transient")

    if (!this.isTouchEnabled() && this.hasSoundHelpTarget) {
      this.soundHelpTarget.remove()
    }

    document.addEventListener("turbo:visit", () => {
      this.stop()
    })

    // noSleep se deshabilita cuando el usuario switchea la app,
    // así que lo re-habilito cuando vuelve (y hace click xq necesito que sea user-triggered)
    document.addEventListener(
      'click',
      () => {
        if (this.noSleep && this.noSleep.isEnabled && this.stateValue === "playing") {
          this.noSleep.disable()
          this.noSleep = new NoSleep() // sin esto no me funcionaba
          this.noSleep.enable()
        }
      },
      false,
    );


    document.addEventListener("visibilitychange", () => {
      if (this.stateValue === "playing" && document.visibilityState !== "visible") {
        // se va de la app pero el timer está corriendo!!
        this.messageSW("focus-check")
      }
    })
  }

  deSetupBrowser(){
    this.setAudioSession("playback")
  }

  // @see https://github.com/w3c/audio-session/blob/main/explainer.md
  setAudioSession(type) {
    if (navigator.audioSession) {
      this.log(`setupAudioSession: setting type to ${type}`)
      navigator.audioSession.type = type;
    }
  }

  setClickAudio(event){
    this.clickAudioTarget.src = event.target.value
    this.preloadAudios()
    if (this.stateValue === "stopped" || this.stateValue === "finished") {
      this.playAudio("click")
    }
  }

  setPhaseChangeAudio(event) {
    this.phaseChangeAudioTarget.src = event.target.value
    this.preloadAudios()
    if (this.stateValue === "stopped" || this.stateValue === "finished") {
      this.playAudio("phaseChange")
    }
  }

  setMetronomeChangeAudio(event) {
    this.metronomeChangeAudioTarget.src = event.target.value
    this.preloadAudios()
    if (this.stateValue === "stopped" || this.stateValue === "finished") {
      this.playAudio("metronomeChange")
    }
  }

  setEndAudio(event) {
    this.endAudioTarget.src = event.target.value
    this.preloadAudios()
    if (this.stateValue === "stopped" || this.stateValue === "finished") {
      this.playAudio("end")
    }
  }

  setStartAudio(event) {
    this.startAudioTarget.src = event.target.value
    this.preloadAudios()
    if (this.stateValue === "stopped" || this.stateValue === "finished") {
      this.playAudio("start")
    }
  }

  // enviar message al service worker
  messageSW(message){
    try {
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage(message);
      } else {
        this.debug('no service worker controller');
      }
    } catch (e) {
      this.error('error messaging service worker', e);
    }
  }

  toggleNavigation(){
    this.log("toggleNavigation")
    switch(this.stateValue){
      case "loading":
      case "stopped":
      case "finished":
        this.log("showing nav")
        this.show(this.tapBar())
        this.show(this.footer())
        break
      case "pre-playing":
      case "playing":
        this.log("hiding nav")
        this.shrinkNavBar()
        this.hide(this.tapBar())
        this.hide(this.footer())
        break
    }
  }

  shrinkNavBar(){
    this.log("shrinking navbar")
    if (this.hasNavbarOutlet) {
      this.navbarOutlet.shrink()
    } else {
      this.error("no navbar outlet")
    }
  }

  tapBar(){
    return document.getElementById("tap-bar")
  }

  footer(){
    return document.querySelector("footer")
  }
}
