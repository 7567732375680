import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { ShowHide } from "./concerns/showHide"

export default class extends Controller {

  static targets = [
    "intervalType",
    "durationMinutes",
    "durationSeconds",
    "durationMessage"
  ]
  static values = {
    state: String,
    name: String,
    position: Number,
    playClick: Boolean,
  }

  static outlets = ["playlist"]

  initialize() {
    Loggable(this, {debug: false})
    ShowHide(this, { hiddenClassName: "d-none" })
  }

  connect() {
    // si no funciona stimulus no se ve el player.
    this.show(this.element)
    this.setState("loading")
    this.initClicks()
    this.setState("stopped")
    this.renderDuration()
  }

  initClicks(){
    this.currentClick = 0
  }

  initDurations(){
    this._totalDuration = parseInt(this.durationMinutesTarget.value) * 60 + parseInt(this.durationSecondsTarget.value)
    document.dispatchEvent(new CustomEvent("interval:durationChanged"))
  }

  renderDuration(){
    this.initDurations()
    // render duration in minutes and seconds with trailing zeros on seconds
    this.durationMessageTarget.innerText = `${Math.floor(this._totalDuration / 60)}:${(this._totalDuration % 60).toString().padStart(2, '0')}`
  }
  
  incClick(){
    this.currentClick++
  }

  start(){
    this.initClicks()
    // cacheo valores para minimizar cálculos en el click()
    this.initDurations()
    this.setState("playing")
  }

  continue(){
    // igual a start() pero sin inicializaciones.
    this.setState("playing")
  }

  stop(){
    this.setState("stopped")
  }

  finish(){
    this.setState("finished")
  }

  setState(newValue){
    this.stateValue = newValue
    switch(this.stateValue) {
      case "loading":
        break
      case "stopped":
        break
      case "finished":
        break
      case "pre-playing":
        break
      case "playing":
        break
    }
    document.dispatchEvent(new CustomEvent("interval:stateChanged", {detail: {state: this.stateValue}}))
  }

  // es el callback del worker
  // se ejecuta en cada tick
  click(){
    this.playNeededAudio()
    this.render()
    this.checkDuration()

    this.incClick()
  }

  // Si seteamos una duración, paramos cuando llegamos a esa duración
  checkDuration(){
    if (this.isOverDuration()) {
      this.finish()
    }
  }

  isOverDuration(){
    return this.currentClick >= this._totalDuration
  }

  playNeededAudio(){
    throw "playNeededAudio() not implemented"
  }

  render(){
    this.renderAction()
    this.renderNextActionPreview()
    this.renderElapsedTime()
  }

  // this.currentClick is elapsed seconds
  // show minutes and seconds with trailing zeros on seconds
  renderElapsedTime(){
    let humanClick = this.currentClick + 1
    let message = `${Math.floor(humanClick / 60)}:${(humanClick % 60).toString().padStart(2, '0')}`
    message += ` / ${Math.floor(this._totalDuration / 60)}:${(this._totalDuration % 60).toString().padStart(2, '0')}`
    this.playlistOutlet.elapsedTimeMessageTarget.innerText = message
  }

  renderAction(){
    this.playlistOutlet.actionMessageTarget.innerText = this.actionMessage()
  }

  renderNextActionPreview(){
    if (this.hasNextAction()) {
      this.playlistOutlet.nextActionMessageTarget.innerText = `${this.playlistOutlet.strNextValue}: ${this.nextActionMessage()}`
    } else {
      this.playlistOutlet.renderNextIntervalPreview()
    }
  }

  renderFirstActionPreview(){
    this.playlistOutlet.nextActionMessageTarget.innerText = `${this.playlistOutlet.strNextValue}: ${this.firstActionMessage()}`
  }

  hasNextAction(){
    return false
  }

  actionMessage(){
    return this.nameValue
  }

  nextActionMessage(){
    return this.nameValue
  }

  firstActionMessage(){
    return this.nameValue
  }

}
