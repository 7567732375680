import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { Dismissable } from "./concerns/dismissable"
import { ShowHide } from "./concerns/showHide"

export default class extends Controller {

  static targets = [ "iosInstructions", "androidInstructions", "appleDesktopInstructions" ]

  initialize() {
    Loggable(this, { debug: false })
    Dismissable(this, {expiration: 30})
    ShowHide(this, { hiddenClassName: "d-none" })
  }

  connect() {
    this.log("connected")

    if (this.canInstall()) {
      this.log("can install")
      if (this.isInstalled()) {
        this.log("is installed")
        this.hide(this.element)
        this.setInstalledCss()
      } else {
        this.log("is not installed")
        if (this.wasDismissed()) {
          this.log("said not now")
          this.hide(this.element)
        } else {
          this.log("did not say not now")
          this.showInstructions()
        }
      }
    } else {
      this.log("cannot install")
      this.hide(this.element)
    }
  }

  showInstructions(){
    this.show(this.element)
    if (this.isIos()){
      this.show(this.iosInstructionsTarget);
    } else if (this.isAndroidPhone()) {
      this.show(this.androidInstructionsTarget);
    } else if (this.isAppleDesktop()) {
      this.show(this.appleDesktopInstructionsTarget);
    } else {
      this.log("unknown device")
      this.hide(this.element)
    }
  }

  // check user-agent for safari on ios
  isIos(){
    let ua = window.navigator.userAgent;
    let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    let webkit = !!ua.match(/WebKit/i);
    let safari = !!ua.match(/Safari/i);
    let notChrome = !ua.match(/CriOS/i);
    let notFirefox = !ua.match(/FxiOS/i);
    return iOS && webkit && safari && notChrome && notFirefox;
  }

  isAppleDesktop(){
    // check if it's a mac
    let userAgent = navigator.userAgent.toLowerCase();
    if (navigator.maxTouchPoints || !userAgent.match(/macintosh/))
      return false;
    // check safari version >= 17
    const version = /version\/(\d{2})\./.exec(userAgent)
    if (!version || !version[1] || !(parseInt(version[1]) >= 17))
      return false;
    // hacky way to detect Sonoma
    let audioCheck = document.createElement('audio').canPlayType('audio/wav; codecs="1"') ? true : false;
    let webGLCheck = new OffscreenCanvas(1, 1).getContext('webgl') ? true : false;

    return audioCheck && webGLCheck;
  }

  // check user-agent for chrome on android
  isAndroidPhone(){
    let ua = window.navigator.userAgent;
    let android = !!ua.match(/Android/i);
    let chrome = !!ua.match(/Chrome/i);
    return android && chrome;
  }

  canInstall() {
    if (this.isIos()){
      return 'standalone' in window.navigator;
    } else {
      return this.isAndroidPhone() || this.isAppleDesktop();
    }
  }

  isInstalled() {
    if (this.isIos()){
      return window.navigator.standalone === true;
    } else {
      return (window.matchMedia('(display-mode: standalone)').matches);
    }
  }

  setInstalledCss(){
    var r = document.querySelector(':root');
    r.style.setProperty('--tap-bar-height', '100px');
    r.style.setProperty('--tap-bar-padding-top', '5px');
  }
}
