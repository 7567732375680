import IntervalController from "./interval_controller"

// Este es un intervalo de loop. Se repite X veces un cycle de Y segundos.
export default class extends IntervalController {

  static targets = [
    "fullCycles"
  ]
  static values = {
  }

  initClicks(){
    super.initClicks()
    this._cycleClick = 0
    this._currentCycle = 0
  }

  incClick() {
    super.incClick();
    this._cycleClick = Math.floor(this.currentClick % this._cycleDuration)
    this._currentCycle = Math.floor(this.currentClick / this._cycleDuration)
  }

  renderElapsedTime() {
    super.renderElapsedTime();
    this.playlistOutlet.clickMessageTarget.innerText = `${this._currentCycle+1}/${this.fullCyclesTarget.value}`
  }

  initDurations() {
    this._cycleDuration = parseInt(this.durationMinutesTarget.value) * 60 + parseInt(this.durationSecondsTarget.value)
    this._totalDuration = this._cycleDuration * parseInt(this.fullCyclesTarget.value)
    document.dispatchEvent(new CustomEvent("interval:durationChanged"))
  }

  isLastClickOfCycle(){
    return this._cycleClick === this._cycleDuration - 1
  }

  isLastCycle(){
    return this._currentCycle === parseInt(this.fullCyclesTarget.value) - 1
  }

  hasNextAction() {
    return !this.isLastCycle()
  }

  playNeededAudio(){
    if (this.isOverDuration()) {
      // sound played in playlist
    } else if (this.currentClick === 0 && this.playlistOutlet.hasAudio("start")) {
      this.playlistOutlet.playAudio("start")
    } else if (this.isLastClickOfCycle() && !this.isLastCycle()) {
      this.playlistOutlet.playAudio("metronomeChange")
    } else if (this.playClickValue) {
      this.playlistOutlet.playAudio("click")
    }
  }

}
